<ng-template #toolTipContent>
    {{ copiedPromoCode ? "Copied !" : "Copy" }}
</ng-template>
<!-- <div class="w-100 promo-code-banner display-web">
    {{"HEADER_CONTENT.promo_banner_text" | translate}}
    <div class="promo-code-div ms-2"><span class="promo-code-text ps-1 text_shadows">Cawaena-Early-Bird-2024</span>
    </div>
    <span class="material-symbols-outlined ps-1 fs-6" role="button" [ngbTooltip]="toolTipContent"
        (click)="copyPromoCode()">content_copy</span>
</div> -->
<!-- <div class="w-100 promo-code-banner display-mobile">
    <div class="text-center promo-text-details">{{"HEADER_CONTENT.promo_banner_text" | translate}}</div>
    <div class="promo-code-text px-2 d-flex align-items-center justify-content-center">
        <div class="promo-code-div ms-2"><span class="text_shadows">Cawaena-Early-Bird-2024</span></div> <span
            class="material-symbols-outlined text-white ps-1" role="button" (click)="copyPromoCode()"
            [ngbTooltip]="toolTipContent">content_copy</span>
    </div>
</div> -->
<nav class="navbar navbar-expand-lg bg-transparent fixed-top position-absolute d-none d-sm-none d-md-none d-lg-block">
    <div class="d-flex align-items-end">
        <div class="header-container">
            <a class="navbar-brand w-25" routerLink='/'><img [src]="etoLogo" alt="ETO" width="200" height="40"></a>
            <div class="d-flex justify-content-around min-width-470">
                <a class="navbar-link text-decoration-none" routerLink='../mission'>{{"HEADER_CONTENT.mission" |
                    translate}}</a>
                <a class="navbar-link text-decoration-none" routerLink='../good-reasons'>{{"HEADER_CONTENT.good_reasons"
                    | translate}}</a>
                <a class="navbar-link text-decoration-none"
                    routerLink='../community-guidelines'>{{"HEADER_CONTENT.guidelines" |
                    translate}}</a>
                <a class="navbar-link text-decoration-none" routerLink='../download'>{{"HEADER_CONTENT.get_cawaena" |
                    translate}}</a>
                <!-- <a class="navbar-link text-decoration-none" routerLink='/web-store'>Web store</a> -->

            </div>
            <!-- <div class="dropdown" ngbDropdown>
                <button class="btn btn-secondary dropdown-toggle" ngbDropdownToggle type="button" id="languageDropdown">
                    <span class="fi fi-{{ userLanguage === 'en' ? 'us' : 'de' }}"></span> &nbsp;
                    <span class="drp-text">{{ userLanguage === 'en' ? 'EN' : 'DE' }}</span>
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                    <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'en'}" ngbDropdownItem
                        (click)="changeLanguage('en')">
                        <span class="fi fi-us"></span>&nbsp; EN
                    </button>
                    <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'de'}" ngbDropdownItem
                        (click)="changeLanguage('de')">
                        <span class="fi fi-de"></span>&nbsp; DE
                    </button>
                </div>
            </div> -->
            <!-- <a class="navbar-link btn text-decoration-none download-button" routerLink='../download'>App</a> -->

            <!-- <div class="dropdown" ngbDropdown>
            <button class="btn btn-secondary dropdown-toggle" ngbDropdownToggle type="button" id="languageDropdown">
                <span class="fi fi-{{ userLanguage === 'en' ? 'us' : 'de' }}"></span> &nbsp;
                {{ userLanguage === 'en' ? 'EN' : 'DE' }}
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'en'}" ngbDropdownItem
                    (click)="changeLanguage('en')">
                    <span class="fi fi-us"></span>&nbsp; EN
                </button>
                <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'de'}" ngbDropdownItem
                    (click)="changeLanguage('de')">
                    <span class="fi fi-de"></span>&nbsp; DE
                </button>
            </div>
        </div> -->
        </div>
        <div class="dropdown me-5 mb-1" ngbDropdown>
            <button class="btn btn-secondary dropdown-toggle" ngbDropdownToggle type="button" id="languageDropdown">
                <span class="fi fi-{{ userLanguage === 'en' ? 'us' : 'de' }}"></span> &nbsp;
                <span class="drp-text">{{ userLanguage === 'en' ? 'EN' : 'DE' }}</span>
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'en'}" ngbDropdownItem
                    (click)="changeLanguage('en')">
                    <span class="fi fi-us"></span>&nbsp; EN
                </button>
                <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'de'}" ngbDropdownItem
                    (click)="changeLanguage('de')">
                    <span class="fi fi-de"></span>&nbsp; DE
                </button>
            </div>
        </div>
    </div>
</nav>


<nav id="my-first"
    class="navbar navbar-expand-lg bg-transparent fixed-top position-absolute d-block d-sm-block d-md-block d-lg-none banner-margin">
    <ul>
        <a routerLink='/'><img [src]="etoLogo" alt="ETO" width="153px" height="30px"></a>
        <li class="burger" (click)="toggleMenu()">
            <i class="material-icons">{{ isMenuOpen ? "close" : "menu" }}</i>
        </li>
        <li class="menu" [class.is-open]="isMenuOpen">
            <a class="menu-item" routerLink='../mission' (click)="toggleMenu()">{{"HEADER_CONTENT.mission" |
                translate}}</a>
            <a class="menu-item" routerLink='../good-reasons' (click)="toggleMenu()">{{"HEADER_CONTENT.good_reasons" |
                translate}}</a>
            <a class="menu-item" routerLink='../download' (click)="toggleMenu()">{{"HEADER_CONTENT.get_cawaena" |
                translate}}</a>
            <!-- <a class="menu-item" routerLink='/web-store' (click)="toggleMenu()">Web Store</a> -->
            <a class="menu-item" routerLink='../faq-help' (click)="toggleMenu()">{{"HEADER_CONTENT.faq" |
                translate}}</a>
            <a class="menu-item" routerLink='../licensing' (click)="toggleMenu()">{{"HEADER_CONTENT.licensing" |
                translate}}</a>
            <a class="menu-item" routerLink='../community-guidelines'
                (click)="toggleMenu()">{{"HEADER_CONTENT.guidelines" |
                translate}}</a>
            <hr>
            <!-- <a class="menu-item" routerLink='/' (click)="toggleMenu()">Facebook</a>
            <a class="menu-item" routerLink='/' (click)="toggleMenu()">Instagram</a> -->
            <div class="dropdown" ngbDropdown>
                <button class="btn btn-secondary dropdown-toggle" ngbDropdownToggle type="button" id="languageDropdown">
                    <span class="fi fi-{{ userLanguage === 'en' ? 'us' : 'de' }}"></span> &nbsp;
                    <!-- Use the appropriate flag class for the default language (e.g., 'flag-icon-us' for USA) -->
                    {{ userLanguage === 'en' ? 'English' : 'Deutsch' }}
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                    <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'en'}" ngbDropdownItem
                        (click)="changeLanguage('en')">
                        <span class="fi fi-us"></span>&nbsp; English
                    </button>
                    <button class="dropdown-item" [ngClass]="{'active': userLanguage === 'de'}" ngbDropdownItem
                        (click)="changeLanguage('de')">
                        <span class="fi fi-de"></span>&nbsp; Deutsch
                    </button>
                    <!-- Add more languages as needed -->
                </div>
            </div>
        </li>
    </ul>
</nav>